import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Fingerprint
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxMkMyIDYuNSA2LjUgMiAxMiAyYTEwIDEwIDAgMCAxIDggNCIgLz4KICA8cGF0aCBkPSJNNSAxOS41QzUuNSAxOCA2IDE1IDYgMTJjMC0uNy4xMi0xLjM3LjM0LTIiIC8+CiAgPHBhdGggZD0iTTE3LjI5IDIxLjAyYy4xMi0uNi40My0yLjMuNS0zLjAyIiAvPgogIDxwYXRoIGQ9Ik0xMiAxMGEyIDIgMCAwIDAtMiAyYzAgMS4wMi0uMSAyLjUxLS4yNiA0IiAvPgogIDxwYXRoIGQ9Ik04LjY1IDIyYy4yMS0uNjYuNDUtMS4zMi41Ny0yIiAvPgogIDxwYXRoIGQ9Ik0xNCAxMy4xMmMwIDIuMzggMCA2LjM4LTEgOC44OCIgLz4KICA8cGF0aCBkPSJNMiAxNmguMDEiIC8+CiAgPHBhdGggZD0iTTIxLjggMTZjLjItMiAuMTMxLTUuMzU0IDAtNiIgLz4KICA8cGF0aCBkPSJNOSA2LjhhNiA2IDAgMCAxIDkgNS4yYzAgLjQ3IDAgMS4xNy0uMDIgMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/fingerprint
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Fingerprint = createLucideIcon('FingerprintIcon', [
  ['path', { d: 'M2 12C2 6.5 6.5 2 12 2a10 10 0 0 1 8 4', key: '1jc9o5' }],
  ['path', { d: 'M5 19.5C5.5 18 6 15 6 12c0-.7.12-1.37.34-2', key: '1mxgy1' }],
  ['path', { d: 'M17.29 21.02c.12-.6.43-2.3.5-3.02', key: 'ptglia' }],
  ['path', { d: 'M12 10a2 2 0 0 0-2 2c0 1.02-.1 2.51-.26 4', key: '1nerag' }],
  ['path', { d: 'M8.65 22c.21-.66.45-1.32.57-2', key: '13wd9y' }],
  ['path', { d: 'M14 13.12c0 2.38 0 6.38-1 8.88', key: 'o46ks0' }],
  ['path', { d: 'M2 16h.01', key: '1gqxmh' }],
  ['path', { d: 'M21.8 16c.2-2 .131-5.354 0-6', key: 'drycrb' }],
  ['path', { d: 'M9 6.8a6 6 0 0 1 9 5.2c0 .47 0 1.17-.02 2', key: '1fgabc' }],
]);

export default Fingerprint;
