import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Cctv
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNyA5aC4wMSIgLz4KICA8cGF0aCBkPSJNMTYuNzUgMTJIMjJsLTMuNSA3LTMuMDktNC4zMiIgLz4KICA8cGF0aCBkPSJNMTggOS41bC00IDgtMTAuMzktNS4yYTIuOTIgMi45MiAwIDAgMS0xLjMtMy45MUwzLjY5IDUuNmEyLjkyIDIuOTIgMCAwIDEgMy45Mi0xLjNaIiAvPgogIDxwYXRoIGQ9Ik0yIDE5aDMuNzZhMiAyIDAgMCAwIDEuOC0xLjFMOSAxNSIgLz4KICA8cGF0aCBkPSJNMiAyMXYtNCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/cctv
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Cctv = createLucideIcon('CctvIcon', [
  ['path', { d: 'M7 9h.01', key: '19b3jx' }],
  ['path', { d: 'M16.75 12H22l-3.5 7-3.09-4.32', key: '1h9vqe' }],
  [
    'path',
    {
      d: 'M18 9.5l-4 8-10.39-5.2a2.92 2.92 0 0 1-1.3-3.91L3.69 5.6a2.92 2.92 0 0 1 3.92-1.3Z',
      key: 'q5d122',
    },
  ],
  ['path', { d: 'M2 19h3.76a2 2 0 0 0 1.8-1.1L9 15', key: '19bib8' }],
  ['path', { d: 'M2 21v-4', key: 'l40lih' }],
]);

export default Cctv;
