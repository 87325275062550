import createLucideIcon from '../createLucideIcon';

/**
 * @component @name AlarmSmoke
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCA4YTIgMiAwIDAgMS0yLTJWM2gyMHYzYTIgMiAwIDAgMS0yIDJaIiAvPgogIDxwYXRoIGQ9Im0xOSA4LS44IDNjLS4xLjYtLjYgMS0xLjIgMUg3Yy0uNiAwLTEuMS0uNC0xLjItMUw1IDgiIC8+CiAgPHBhdGggZD0iTTE2IDIxYzAtMi41IDItMi41IDItNSIgLz4KICA8cGF0aCBkPSJNMTEgMjFjMC0yLjUgMi0yLjUgMi01IiAvPgogIDxwYXRoIGQ9Ik02IDIxYzAtMi41IDItMi41IDItNSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/alarm-smoke
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const AlarmSmoke = createLucideIcon('AlarmSmokeIcon', [
  ['path', { d: 'M4 8a2 2 0 0 1-2-2V3h20v3a2 2 0 0 1-2 2Z', key: '2c4fvq' }],
  ['path', { d: 'm19 8-.8 3c-.1.6-.6 1-1.2 1H7c-.6 0-1.1-.4-1.2-1L5 8', key: '1vrndv' }],
  ['path', { d: 'M16 21c0-2.5 2-2.5 2-5', key: '1o3eny' }],
  ['path', { d: 'M11 21c0-2.5 2-2.5 2-5', key: '1sicvv' }],
  ['path', { d: 'M6 21c0-2.5 2-2.5 2-5', key: 'i3w1gp' }],
]);

export default AlarmSmoke;
